<template>
    <UiPopupCard
        v-model="isOpen"
        :anchor="anchor"
        :placement="centered ? 'bottom' : 'bottom-start'"
        :class="isSkeletonLoading ? 'cursor-wait' : undefined"
        wrapper-class="sim-search-popup"
        content-class="flex flex-col"
        :offset="{
            mainAxis: 8,
            alignmentAxis: centered ? undefined : -150,
        }"
        size="none"
        match-min-anchor-width
    >
        <!--  LOADING  -->
        <div v-if="isLoading && !isSkeletonLoading" class="flex h-48 items-center justify-center">
            <CoreIconLoadingSpinner small />
        </div>

        <!--  MAIN CONTENT  -->
        <template v-else>
            <!-- PHRASES -->
            <div class="sim-box-shadow sticky top-0 z-10 flex items-center gap-6 bg-white pl-6">
                <div class="sim-eyebrow shrink-0">
                    {{ phrases.title }}
                </div>

                <div class="scrollbar-none flex items-center gap-3 overflow-x-auto py-4 pl-1.5 pr-6">
                    <BaseUiButton
                        v-for="(phrase, index) in phrases.values"
                        :key="index"
                        color="simplo"
                        class="whitespace-nowrap lowercase"
                        pill
                        dense
                        :aria-label="$t('accessibility.search_for_phrase', [phrase])"
                        @click="searchForPhrase(phrase)"
                    >
                        {{ phrase }}
                    </BaseUiButton>
                </div>
            </div>

            <!--  NO RESULTS  -->
            <div v-if="areNoResults" class="px-5 pb-14 pt-12 text-center">
                <h5 class="sim-h sim-h5 mb-4">
                    {{ $t('search.no_results') }}
                </h5>
                <p class="text-normal">
                    {{ $t('search.try_different_term') }}
                </p>
            </div>

            <div v-else class="flex max-h-full max-md:flex-col md:overflow-hidden">

                <!--  LEFT SIDE  -->
                <div class="md:bg-basic-100 sim-scrollbar flex-1 md:overflow-y-auto">
                    <!--  CATEGORIES  -->
                    <div class="p-6">
                        <div class="sim-eyebrow mb-3.5">
                            {{ syncedSearchQuery ? $t('labels.categories') : $t('labels.top_categories') }}

                            <UiCountIndicatorText
                                v-if="syncedSearchQuery"
                                :count="searchResult?.categories?.total ?? 0"
                                color="simplo"
                                show-x
                            />

                        </div>

                        <div v-if="searchResult?.categories?.getItems().length" class="flex flex-wrap gap-3">
                            <template v-if="isSkeletonLoading">
                                <UiCardWithImage
                                    v-for="category in searchResult?.categories?.getItems()"
                                    :key="category.model.id!"
                                    :src="category.model.thumbnailImageUrl ?? category.model.imageUrl"
                                    skeleton="basic-dark"
                                    class="sim-text-normal"
                                    small
                                >
                                    {{ category.model.name }}
                                </UiCardWithImage>
                            </template>

                            <template v-else>
                                <NuxtLink
                                    v-for="category in searchResult?.categories?.getItems()"
                                    :key="category.model.id!"
                                    :to="category.model.urls ?? ''"
                                    class="rounded-md"
                                >
                                    <UiCardWithImage
                                        :src="category.model.thumbnailImageUrl ?? category.model.imageUrl"
                                        class="sim-text-normal"
                                        small
                                        floating
                                        hover
                                    >
                                        <BaseUiTextHighlight
                                            :chunks="category.getChunksName()"
                                        />
                                    </UiCardWithImage>
                                </NuxtLink>
                            </template>
                        </div>

                        <template v-else>
                            <BaseUiSkeleton v-if="isSkeletonLoading" color="basic-dark" class="inline">
                                <span class="invisible">
                                    {{ $t('search.no_categories_found') }}
                                </span>
                            </BaseUiSkeleton>
                            <p v-else>
                                {{ $t('search.no_categories_found') }}
                            </p>
                        </template>
                    </div>

                    <!--  BRANDS  -->
                    <hr>

                    <div class="p-6">
                        <div class="sim-eyebrow mb-3.5">
                            {{ syncedSearchQuery ? $t('labels.brands') : $t('labels.top_brands') }}

                            <UiCountIndicatorText
                                v-if="syncedSearchQuery"
                                :count="searchResult?.brands?.total ?? 0"
                                color="simplo"
                                show-x
                            />
                        </div>

                        <div v-if="searchResult?.brands?.getItems().length" class="flex flex-wrap gap-3">
                            <template v-if="isSkeletonLoading">
                                <UiCard
                                    v-for="brand in searchResult?.brands?.getItems()"
                                    :key="brand.model.id!"
                                    skeleton="basic-dark"
                                    class="sim-text-normal"
                                    small
                                >
                                    {{ brand.model.name }}
                                </UiCard>
                            </template>

                            <template v-else>
                                <NuxtLink
                                    v-for="brand in searchResult?.brands?.getItems()"
                                    :key="brand.model.id!"
                                    class="rounded-md"
                                    :to="brand.model.urls ?? ''"
                                >
                                    <UiCard
                                        class="sim-text-normal"
                                        medium
                                        floating
                                        hover
                                    >
                                        <BaseUiTextHighlight
                                            :chunks="brand.getChunksName()"
                                        />
                                    </UiCard>
                                </NuxtLink>
                            </template>


                        </div>

                        <template v-else>
                            <BaseUiSkeleton v-if="isSkeletonLoading" color="basic-dark" class="inline">
                                <span class="invisible">
                                    {{ $t('search.no_brands_found') }}
                                </span>
                            </BaseUiSkeleton>
                            <p v-else>
                                {{ $t('search.no_brands_found') }}
                            </p>
                        </template>
                    </div>

                    <!--  ARTICLES  -->
                    <hr>

                    <div class="p-6">
                        <div class="sim-eyebrow mb-3.5">
                            {{ syncedSearchQuery ? $t('labels.articles') : $t('labels.top_articles') }}

                            <UiCountIndicatorText
                                v-if="syncedSearchQuery"
                                :count="searchResult?.articles?.total ?? 0"
                                color="simplo"
                                show-x
                            />
                        </div>

                        <div v-if="searchResult?.articles?.getItems().length" class="flex flex-col gap-3">

                            <template v-if="isSkeletonLoading">
                                <BaseUiSkeleton
                                    v-for="article in searchResult?.articles?.getItems()"
                                    :key="article.model.id!"
                                    color="basic-dark"
                                >
                                    <span class="invisible">
                                        {{ article.model.name }}
                                    </span>
                                </BaseUiSkeleton>
                            </template>

                            <template v-else>
                                <NuxtLink
                                    v-for="article in searchResult?.articles?.getItems()"
                                    :key="article.model.id!"
                                    :to="article.model.urls ?? ''"
                                    class="hover:underline"
                                >
                                    <BaseUiTextHighlight
                                        class="sim-text-small"
                                        :chunks="article.getChunksName()"
                                    />
                                </NuxtLink>
                            </template>
                        </div>

                        <template v-else>
                            <BaseUiSkeleton v-if="isSkeletonLoading" color="basic-dark" class="inline">
                                <span class="invisible">
                                    {{ $t('search.no_articles_found') }}
                                </span>
                            </BaseUiSkeleton>
                            <p v-else>
                                {{ $t('search.no_articles_found') }}
                            </p>
                        </template>
                    </div>
                </div>

                <div class="flex-2 sim-scrollbar flex flex-col md:overflow-y-auto">
                    <!--  PRODUCTS  -->
                    <hr class="md:hidden">


                    <div class="flex flex-col p-6">
                        <div class="sim-eyebrow mb-3.5">
                            {{ syncedSearchQuery ? $t('labels.products') : $t('labels.top_products') }}

                            <UiCountIndicatorText
                                v-if="syncedSearchQuery"
                                :count="searchResult?.products?.total ?? 0"
                                color="simplo"
                                show-x
                            />
                        </div>

                        <div v-if="searchResult?.products?.getItems().length"
                             class="-mx-6 flex flex-col"
                        >
                            <template v-if="isSkeletonLoading">
                                <BaseUiSkeleton
                                    v-for="product in searchResult?.products?.getItems()"
                                    :key="product.model.id!"
                                    class="mx-6 my-2 h-24"
                                    color="gray"
                                />
                            </template>

                            <template v-else>
                                <NuxtLink v-for="product in searchResult?.products?.getItems()"
                                          :key="product.model.id!"
                                          :to="product.model.getUrl() ?? ''"
                                          class="sim-inline-product"
                                >
                                    <ProductCardInline
                                        class="px-6 py-1"
                                        :product="product"
                                    />
                                </NuxtLink>
                            </template>
                        </div>

                        <template v-else>
                            <BaseUiSkeleton v-if="isSkeletonLoading" color="gray" class="mr-auto">
                                <span class="invisible">
                                    {{ $t('search.no_products_found') }}
                                </span>
                            </BaseUiSkeleton>
                            <p v-else>
                                {{ $t('search.no_products_found') }}
                            </p>
                        </template>
                    </div>

                    <!-- TODO: add when search results page is implemented -->
                    <!--                    <div class="p-6">-->
                    <!--                        &lt;!&ndash;  BUTTON  &ndash;&gt;-->
                    <!--                        <BaseUiButton color="primary" class="w-full">-->
                    <!--                            <template #leading>-->
                    <!--                                <IconSearch />-->
                    <!--                            </template>-->

                    <!--                            {{ $t('search.show_all_results') }}-->
                    <!--                        </BaseUiButton>-->
                    <!--                    </div>-->
                </div>
            </div>
        </template>
    </UiPopupCard>
</template>

<script lang="ts" setup>
import type { SearchResultModel } from '@simploshop-models/search-result.model'

const {
    anchor,
    centered = true,
} = defineProps<{
    anchor: HTMLElement
    centered?: boolean
}>()

const { t } = useI18n()

let didQueryChange = false

const isOpen = defineModel<boolean>()
const _query = defineModel<string>('query')
const _queryOverride = ref<string | null>(null) // query override used to fetch the default search result
const searchQuery = computed(() => (_queryOverride.value ?? _query.value ?? '').trim())
const syncedSearchQuery = ref<string>('')

function searchForPhrase(phrase: string | null) {
    if (!phrase || !phrase.trim()) return
    _query.value = phrase
}

// DATA FETCHING -------------------------------------------------------------------------------------------------------

const defaultSearchResult = shallowRef<SearchResultModel | null>(null)

const { item: _searchResult, execute: fetchSearchResult, status: searchResultFetchStatus } = useSearchResultsApiService()
    .setParam('search', () => searchQuery.value || undefined)
    .setParam('highlighted', 1)
    .useGet({
        immediate: false,
        watch: false,
    })

const searchResult = computed(() => syncedSearchQuery.value ? _searchResult.value : defaultSearchResult.value)

const phrases = computed<{ title: string, values: string[] }>(() => {
    // the phrases from the actual search result, not the default one
    const searchResultPhrases = _searchResult.value?.phrases?.getItems() ?? []

    if (searchResultPhrases.length && syncedSearchQuery.value) {
        return {
            title: t('labels.phrases'),
            values: searchResultPhrases.map(phrase => phrase.model.term!),
        }
    }

    // the phrases from the default search result
    const defaultSearchResultPhrases = defaultSearchResult.value?.phrases?.getItems() ?? []
    return {
        title: t('labels.top_phrases'),
        values: defaultSearchResultPhrases.map(phrase => phrase.model.term!),
    }
})

// ---------------------------------------------------------------------------------------------------------------------

/**
 * Whether the popup should show a loading spinner.
 */
const isLoading = computed<boolean>(() => searchResultFetchStatus.value === 'pending')
const isSkeletonLoading = computed<boolean>(() => isLoading.value && !!defaultSearchResult.value)

/**
 * Whether there are no results to show.
 */
const areNoResults = computed<boolean>(() => Boolean(
    searchResult?.value?.areNoResults() &&
    !isLoading.value
))

/**
 * A function that fetches products and categories based on the current query.
 * !! Do not use this function directly, use its debounced version instead.
 */
async function handleSearch() {
    if (!searchQuery.value) return
    await fetchSearchResult()
    syncedSearchQuery.value = searchQuery.value
    didQueryChange = false
}

async function fetchDefaultResult() {
    _queryOverride.value = ''
    await fetchSearchResult()
    defaultSearchResult.value = _searchResult?.value ?? null
    _queryOverride.value = null
}

watch(isOpen, async (isOpen) => {
    if (!isOpen) return

    if (!defaultSearchResult.value) {
        await fetchDefaultResult()
    }

    if (!didQueryChange) return
    await handleSearch()
}, { immediate: true })


// execute search
watch(searchQuery, (val) => {
    if (!val) {
        syncedSearchQuery.value = ''
        return
    }
    didQueryChange = true
    handleSearch()
})

</script>

<style lang="scss" scoped>

.sim-search-popup {
    width: max(62rem, 100%);
}

.sim-inline-product:not(:last-child) {
    border-bottom: hr-line();
}


</style>
